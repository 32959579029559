import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import styles from './Select.module.css';
import useOutsideClick from '../../hooks/useOutsideClick';


function Select({ items, activeItem, onSelect, withImage }) {
  const wrapperRef = useRef(null);
  const { clickedOutside } = useOutsideClick(wrapperRef);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (clickedOutside) {
      setIsOpen(false);
    }
  }, [clickedOutside]);

  return (
    <div
      ref={wrapperRef}
      className={classNames(styles.wrapper, { [styles.active]: isOpen })}
      onClick={() => {
        setIsOpen((state) => !state);
      }}
    >
      {withImage && (
        <img className={styles.img} src={`/${activeItem.name}.svg`} width="16" height="16" alt={activeItem.name} />
      )}
      <span className={styles.activeElement}>
        {activeItem.alias}  
      </span>
      {isOpen && (
        <div className={styles.dd}>
          {items
            .filter(({name}) => name !== activeItem.name)
            .map(({alias, name}, index) => {
              return (
                <div
                  className={classNames(styles.item, {
                    [styles['with-image']]: withImage,
                  })}
                  key={index}
                  onClick={() => onSelect({name, alias})}
                >
                  {withImage && (
                    <img className={styles.img} src={`/${name}.svg`} width="16" height="16" alt="" />
                  )}{' '}
                  <span>{alias}</span>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
}

export default Select;
