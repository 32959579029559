import { useCallback, useState, useRef, useEffect } from 'react';
import './App.css';
import integrationLayer from './services/integration';
import config, { isBetterHost } from './hostsConfig';
import Header from './components/Header/Header';
import { useUser } from './components/UserProvider/UserProvider';
import { useLanguageService } from './translations';
import useNavigation, { getUrlLang, getQueryString } from './hooks/useNavigation';

const currentHost = window.location.host;

let innerAppHost = 'https://pmunited-azcom.stage.gr8.tech/';
if (config.has(currentHost)) {
  const innerHost = config.get(currentHost);
  let protocol = 'https';
  if (innerHost.indexOf('localhost') > -1) {
    protocol = 'http';
  }
  innerAppHost = `${protocol}://${innerHost}`;
}

document.querySelector('#page-title').innerHTML = isBetterHost
  ? 'BETER iFrame Demo'
  : 'GR8Tech Iframe demo brand';
document.querySelector('#favicon').href = isBetterHost
  ? '/better_favicon.png'
  : '/favicon.ico';

let lastUrl = null;

const prepareUrl = (lang, path = '') => {
  return innerAppHost + `/${lang}` + path;
};

function App() {
  const { lang, setLang, langCodes } = useLanguageService();
  const [url, setUrl] = useState(null);
  const [currency, setCurrency] = useState();
  const [isLogoutInFlight, setIsLogoutInFlight] = useState(false);
  const [refreshBalance, setRefreshBalance] = useState(0);
  const { user, setUser, isAuthPending, setIsAuthPending } = useUser();
  const [sdk, setSdk] = useState({});
  const { navigate, navigateByPath, iframePath, clientPath, search } = useNavigation(
    lang,
    langCodes,
  );
  const userRef = useRef({});
  const routerRef = useRef({ prevPath: iframePath, prevSearch: search });

  useEffect(() => {
    const { prevPath } = routerRef.current;
    setUrl(prepareUrl(lang, prevPath));
    navigate({ path: prevPath, language: lang });
  }, [lang, navigate]);

  useEffect(() => {
    if (!url) {
      const urlLang = getUrlLang(clientPath, langCodes);
      urlLang && urlLang !== lang && setLang(urlLang);
    }
  }, [clientPath, lang, langCodes, setLang, url]);

  const login = useCallback(() => {
    navigateByPath('/login');
  }, [navigateByPath]);

  useEffect(() => {
    const { prevPath } = routerRef.current;
    const integration = integrationLayer(prevPath);
    const { unlistenMessages } = integration.listenMessages({
      loginButtonClick: () => {
        unlistenMessages();
        login();
      },
      authFailedCallback: () => {
        alert('Something went wrong');
      },
      refreshBalanceCallback: () => {
        setRefreshBalance((v) => v + 1);
      },
      navigationCallback: ({ path, query }) => {
        const fullPath = `${path}${getQueryString(query, 'jwt')}`;
        if (fullPath === routerRef.current.prevPath) return;
        navigate({ path: fullPath });
        routerRef.current.prevPath = fullPath;
      },
      logoutCallback: () => {
        setUser(null);
        userRef.current.loggedOut = true;
      },
    });
    setSdk(integration);
  }, [login, navigate, setUser, clientPath, lang]);

  const logout = useCallback(async () => {
    await sdk.logout();
    setUser(null);
  }, [setUser, sdk]);

  const checkUser = useCallback(async () => {
    if (!sdk.getUser) return;
    if (localStorage.getItem('token')) {
      const user = await sdk.getUser();
      setUser(user);
    }
  }, [setUser, sdk]);

  useEffect(() => {
    if (!user && userRef.current.loggedOut) {
      logout();
    }
  }, [logout, user]);

  useEffect(() => {
    if (user?.jwt) {
      const { prevSearch } = routerRef.current;
      const query = prevSearch ? prevSearch.replace('?', '&') : '';
      setUrl((url) => {
        const data = new URL(url + (lastUrl || ''));
        const search = new URLSearchParams({
          jwt: user.jwt.jwt,
        });
        return data.origin + data.pathname + '?' + search.toString() + query;
      });
      setIsAuthPending(false);
    }
  }, [setIsAuthPending, user?.jwt, lang, search]);

  useEffect(() => {
    checkUser();
  }, [refreshBalance, checkUser]);

  /** currency overrider */
  // useEffect(() => {
  //   const frame = document.getElementById('betbook');
  //   frame.onload = () => {
  //     if (user?.balance && url.indexOf('jwt') > -1) {
  //       console.log('override balace ', user);
  //       sdk.postMessage({ balance: user?.balance, currency: user?.currency });
  //     }
  //   };
  // }, [user, sdk, url]);

  const changeCurrency = (currency) => {
    setIsLogoutInFlight(true);
    sdk.postMessage({ logout: true });
    setCurrency(currency);
  };

  const onLoadCallback = () => {
    if (isLogoutInFlight) {
      sdk.getJwt(user.authToken, currency).then(({ jwt }) => {
        setUser((user) => ({ ...user, jwt: { jwt } }));
        setIsLogoutInFlight(false);
      });
    }
  };

  const refreshCallback = () => {
    navigateByPath('/');
    sdk.reload();
  };

  if (!url || isAuthPending) {
    return null;
  }

  return (
    <div className="App">
      <Header
        currency={currency}
        user={user}
        login={login}
        logout={logout}
        refresh={refreshCallback}
        updateLang={setLang}
        changeCurrency={changeCurrency}
      />
      <iframe
        onLoad={onLoadCallback}
        name="target"
        id="betbook"
        frameBorder="0"
        className="betbook"
        src={url}
        title="stage"
        allow="autoplay"
            ></iframe>
    </div>
  );
}

export default App;
