class Auth {
  constructor() {
    /**
     * authToken
     * balance
     * currency
     * login
     */
    this.user = null;
    this.jwt = null;
    /** host is added here to override for local development */
    this.host =
      window.location.host.indexOf('localhost') > -1
        ? 'iframe-holder-fe.sport-stage.pmcorp.loc'
        : window.location.host;
    this.headers = {
      accept: 'text/plain',
      'Content-Type': 'application/json-patch+json',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE',
      'Access-Control-Allow-Headers': 'Content-Type',
    };
  }

  async login({ login, password }) {
    try {
      const res = await fetch(`https://${this.host}/api/v1/auth/login`, {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          ...this.headers,
        },
        body: JSON.stringify({ login, password }),
      });

      const user = await res.json();
      this.user = user;
      const jwt = await this.getJwt(user.authToken);
      return { ...user, jwt };
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async getJwt(sessionId, currency) {
    const query = currency ? `currency=${currency}` : '';
    try {
      const res = await fetch(
        `https://${this.host}/api/v1/auth/iframe-jwt?${query}`,
        {
          method: 'GET',
          headers: {
            ...this.headers,
            Authorization: sessionId,
          },
        },
      );

      const jwt = await res.json();
      this.jwt = jwt;
      return jwt;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  async logout() {
    try {
      await fetch(`https://${this.host}/api/v1/auth/logout`, {
        method: 'DELETE',
        headers: {
          accept: 'text/plain',
          'Content-Type': 'application/json-patch+json',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, PUT, DELETE',
          'Access-Control-Allow-Headers': 'Content-Type',
          Authorization: this.user.authToken,
        },
      });

      this.jwt = null;
      this.user = null;
    } catch (e) {
      console.error(e);
    }
  }

  async userInfo(token) {
    try {
      const res = await fetch(`https://${this.host}/api/v1/users/info`, {
        method: 'GET',
        headers: {
          ...this.headers,
          Authorization: token,
        },
      });
      if (!res.ok) {
        localStorage.removeItem('token');
        throw new Error("HTTP status " + res.status);
      }

      const user = await res.json();
      this.user = user;
      let jwt = null;
      if (!this.jwt) {
        jwt = await this.getJwt(user.authToken);
      } else {
        jwt = this.jwt;
      }
      return { ...user, jwt };
    } catch (e) {
      console.error(e);
    }
  }
}

const auth = new Auth();

export default auth;
