import { useEffect, useState } from 'react';

const useViewMode = (size) => {
  const [matches, setMatches] = useState(false);
  const [resize, setResize] = useState(0);

  const callback = () => {
    setResize((v) => v + 1);
  };

  useEffect(() => {
    window.addEventListener('resize', callback);
    return () => {
      window.removeEventListener('resize', callback);
    };
  });

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const mq = window.matchMedia(`(max-width: 1280px)`);
      setMatches(mq.matches);
    }
  }, [size, resize]);

  return { isWideView: !matches };
};

export default useViewMode;
