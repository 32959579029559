import styles from './Button.module.css';

const Button = ({ action, children, type, disabled }) => {
	return (
		<button
			disabled={disabled}
			type="button"
			className={`${styles.button} ${styles[type]}`}
			onClick={action}
		>
			{children}
		</button>
	);
};

export default Button;
