import { useEffect, useState } from 'react';

function useOutsideClick(ref) {
  const [clickedOutside, setClickedOutside] = useState();
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setClickedOutside(true);
      } else {
        setClickedOutside(false);
      }
    }

    const iframe = document.getElementById('betbook');
    iframe.addEventListener('load', function () {
      try {
        const iframeDoc =
          iframe.contentDocument || iframe.contentWindow.document;
        iframeDoc.addEventListener('mousedown', handleClickOutside);
      } catch (e) {}
    });

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return {
    clickedOutside,
  };
}

export default useOutsideClick;
