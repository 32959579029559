const beterStageHost = 'iframe-holder-beter-fe.sport.pmcorp.loc'; // todo check
const beterProdHost = 'iframe-test1.online';
const newBeterProdHost = 'iframe.beter.co';
const demoBrandProdHost = 'sport-iframe.sdfojsd.com';
const iframeEuHost = 'gr8-iframe-eu.com';
const iframeAsiaHost = 'gr8-iframe-asia.com';
const iframeLatamHost = 'gr8-iframe-latam.com';
const config = new Map();

config.set('localhost:3000', 'localhost:8080');
config.set(
  'iframe-holder-fe.sport-stage.pmcorp.loc',
  'pmunited-azcom.stage.gr8.tech',
);
config.set('iframe-holder-fe.stage.gr8.tech', 'pmunited-azcom.stage.gr8.tech');

config.set('demo-iframe.gr8.tech', demoBrandProdHost);
config.set(iframeEuHost, 'sport-iframe.fivreo.com');
config.set(iframeAsiaHost, 'sport-iframe.gr8-iframe-asia.com');
config.set(iframeLatamHost, 'sport-iframe.gr8-iframe-latam.com');

config.set('iframe-holder-fe.sport.pmcorp.loc', demoBrandProdHost); // todo check
config.set(beterStageHost, demoBrandProdHost); // todo check
config.set(beterProdHost, 'sport-iframe.iframe-test1.online');
config.set(newBeterProdHost, 'esport-iframe.beter.co');
config.set('iframe-test.online', 'sport-iframe.iframe-test.online');

const host = window.location.host;

export const isBetterHost =
  host === beterProdHost ||
  host === beterStageHost ||
  host === newBeterProdHost;

export const isBeterEsportHost = host === beterProdHost;

export const isProdHost = [
  'demo-iframe.gr8.tech',
  beterProdHost,
  newBeterProdHost,
  demoBrandProdHost,
].includes(window.location.host);

export const isIframeEuHost = host === iframeEuHost;
export const isIframeAsiaHost = host === iframeAsiaHost;
export const isIframeLatamHost = host === iframeLatamHost;

export default config;
