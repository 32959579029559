import { useMemo, useState} from 'react';
import { isBetterHost } from './hostsConfig';

function addLang(code, alias) {
  return {
    name: code,
    alias: alias || code,
  };
}

class LanguagesService {
  constructor(isBetterHost) {
    this.translations = {
      en: {
        Balance: 'Balance',
        Deposit: 'Deposit',
        Logout: 'Logout',
        'Log in': 'Log in',
        Username: 'Username',
        Password: 'Password',
        'Welcome back': 'Welcome back',
        Greeting:
          'We happy to see you here again. Enter your username and password',
        LoginError: "Can't login user with requested credentials.",
      },
      es: {
        Balance: 'Balance',
        Deposit: 'Depósito',
        Logout: 'Cerrar sesión',
        'Log in': 'Acceso',
        Username: 'Nombre de usuario',
        Password: 'Contraseña',
        'Welcome back': 'Bienvenido de nuevo',
        Greeting:
          'Estamos felices de verte aquí de nuevo. Ingrese su nombre de usuario y contraseña',
        LoginError:
          'No se puede iniciar sesión como usuario con las credenciales solicitadas.',
      },
      pt: {
        Balance: 'Equilíbrio',
        Deposit: 'Depósito',
        Logout: 'Sair',
        'Log in': 'Conecte-se',
        Username: 'Nome de usuário',
        Password: 'Senha',
        'Welcome back': 'Bem vindo de volta',
        Greeting:
          'Estamos felizes em ver você aqui novamente. Digite seu nome de usuário e senha',
        LoginError:
          'Não é possível fazer login do usuário com as credenciais solicitadas.',
      },
      fr: {
        Balance: 'Équilibre',
        Deposit: 'Dépôt',
        Logout: 'Se déconnecter',
        'Log in': 'Se connecter',
        Username: "Nom d'utilisateur",
        Password: 'Mot de passe',
        'Welcome back': 'Content de te revoir',
        Greeting:
          "Nous sommes heureux de vous revoir ici. Entrez votre nom d'utilisateur et votre mot de passe",
        LoginError:
          "Impossible de connecter l'utilisateur avec les informations d'identification demandées.",
      },
      zh: {
        Balance: '平衡',
        Deposit: '订金',
        Logout: '登出',
        'Log in': '登录',
        Username: '用户名',
        Password: '密码',
        'Welcome back': '欢迎回来',
        Greeting: '我们很高兴再次在这里见到您。 输入你的用户名与密码',
        LoginError: '无法使用请求的凭据登录用户。',
      },
      hi: {
        Balance: 'शेष राशि',
        Deposit: 'जमा',
        Logout: 'लॉगआउट',
        'Log in': 'लॉग इन',
        Username: 'उपयोगकर्ता नाम',
        Password: 'पासवर्ड',
        'Welcome back': 'वापसी पर स्वागत है',
        Greeting: 'ग्रीटिंग',
        LoginError: 'लॉगिनत्रुटि',
      },
      tr: {
        Balance: 'Denge',
        Deposit: 'Depozito',
        Logout: 'Çıkış Yap',
        'Log in': 'Giriş yapmak',
        Username: 'Kullanıcı adı',
        Password: 'Şifre',
        'Welcome back': 'tekrar hoşgeldiniz',
        Greeting: 'Selamlama',
        LoginError: 'Sisteme giriş hatası',
      },
      de: {
        Balance: 'Gleichgewicht',
        Deposit: 'Kaution',
        Logout: 'Ausloggen',
        'Log in': 'Anmeldung',
        Username: 'Nutzername',
        Password: 'Passwort',
        'Welcome back': 'Willkommen zurück',
        Greeting:
          'Wir freuen uns, Sie wieder hier zu sehen. Geben Sie Ihren Benutzernamen und Ihr Passwort ein',
        LoginError: "Benutzer kann sich mit den angeforderten Anmeldeinformationen nicht anmelden.",
      },
    };
    this.languages = isBetterHost
      ? [addLang('en'), addLang('es'), addLang('pt'), addLang('zh', '汉语')]
      : [
          addLang('en'),
          addLang('de'),
          addLang('fr'),
          addLang('es'),
          addLang('pt'),
          addLang('tr'),
          addLang('hi', 'हिन्दी'),
          addLang('zh', '汉语'),
        ];

    const lang = localStorage.getItem('lang');
    const hasLang = this.languages
      .map(({ name }) => name)
      .find((name) => name === lang);
    this.defaultLanguage = lang !== 'null' && !!lang && hasLang ? lang : 'en';
    this.lang = this.defaultLanguage;
    localStorage.setItem('lang', this.lang);
  }

  t(key) {
    return this.translations[this.lang][key] || key;
  }

  setLang(lang) {
    this.lang = lang;
    localStorage.setItem('lang', this.lang);
  }
}

const instance = new LanguagesService(isBetterHost);

export default instance;

export const useLanguageService = () => {
  const [lang, setLang] = useState(instance.lang);

  instance.setLang(lang);
  const t = instance.t.bind(instance);
  const langCodes = useMemo(()=> instance.languages.map(({ name }) => name), [])

  return {
    setLang,
    lang,
    t,
    languages: instance.languages,
    langCodes,
  };
};
