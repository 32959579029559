import { createContext, useContext, useState } from 'react';

const UserContext = createContext(null);

function UserProvider({ children }) {
	const [user, setUser] = useState();
	const [isAuthPending, setIsAuthPending] = useState();

	return (
		<UserContext.Provider value={{ user, setUser, isAuthPending, setIsAuthPending }}>
			{children}
		</UserContext.Provider>
	);
}

export const useUser = () => useContext(UserContext);

export default UserProvider;
