import React, { useEffect, useState } from 'react';
import styles from './Input.module.css';

function Input({ placeholder, type, onChange, error, value }) {
	const [isFocused, setIsFocused] = useState(false);
	const [hasError, setError] = useState(false);
	const [val, setValue] = useState(value);

	useEffect(() => {
		if (value) {
			setIsFocused(true);
		}
	}, [value]);

	useEffect(() => {
		setError(error);
	}, [error]);

	const handleFocus = () => {
		setIsFocused(true);
	};

	const handleBlur = () => {
		if (val === '') {
			setIsFocused(false);
		}
	};

	const handleChange = (e) => {
		setValue(e.target.value);
		onChange(e.target.value);
		setError(null);
	};

	return (
		<div
			className={`${styles['input-container']} ${
				isFocused ? `${styles.focused}` : ''
			} ${hasError && styles.error}`}
		>
			<input
				type={type}
				value={val}
				onChange={handleChange}
				onFocus={handleFocus}
				onBlur={handleBlur}
			/>
			<label>{placeholder}</label>
		</div>
	);
}

export default Input;
