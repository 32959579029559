import LoginForm from '../components/LoginForm/LoginForm';
import integrationLayer from '../services/integration';
import { useNavigate } from 'react-router-dom';

import styles from './LoginPage.module.css';
import { useCallback, useState } from 'react';
import { useLanguageService } from '../translations';
import { useUser } from '../components/UserProvider/UserProvider';

function LoginPage() {
	const { t } = useLanguageService();
	const [error, setError] = useState('');
	const navigate = useNavigate();
	const {  setIsAuthPending } = useUser();

	const onFormDataChange = useCallback(() => {
		setError('');
	}, []);

	const login = useCallback(
		async (data) => {
			try {
				const { login, prevPath} = integrationLayer();

				const res = await login(data);
				if (!res) {
					setError(t('LoginError'));
				}
				if (res.code) {
					setError(res.message);
				} else {
					localStorage.setItem('token', res.authToken);
					setIsAuthPending(true);
					navigate(prevPath);
				}
			} catch (e) {
				setError(t('LoginError'));
			}
		},
		[navigate, setIsAuthPending, t]
	);

	return (
		<div className={styles.container}>
			<LoginForm login={login} error={error} onChange={onFormDataChange} />
		</div>
	);
}

export default LoginPage;
